<template>
  <!-- 备注栏和费用明细 -->
  <div class="SpecialNoteVue">
    <div class="noteBox">
      <div class="title">{{ $fanyi('特殊要求备注') }}</div>
      <div class="notetishi">
        <span v-if="$route.query.type == 'offer'">
          {{ $fanyi('如果您有任何问题，请通过 RakuChat 联系专员.') }}
        </span>
        <span v-else>{{
          $fanyi(
            '您可以写下您对产品的任何服务和要求，例如定制包装盒产品定制等。我们的工作人员将会联系您并商讨细节'
          )
        }}
        </span>
      </div>

      <div class="noteInputBox">
        <textarea :class="{
          disabled: ['offer', 'purchased', 'cancel', 'delete'].indexOf($parent.orderStatus) != -1,
        }" :disabled="['offer', 'purchased', 'cancel', 'delete'].indexOf($parent.orderStatus) != -1"
          v-model="$parent.client_remark" :placeholder="$fanyi('备注后会同步显示给业务员')">
        </textarea>
      </div>
    </div>
    <div class="priceBox">
      <h1>{{ $fanyi('全部费用') }}:</h1>
      <div class="priceOrder">
        <p>
          <span>
            {{ $fanyi('产品费用') }}
          </span>
          <font class="otherPrice">
            ${{
              $fun.EURNumSegmentation(
                $fun.ceil(
                  $parent.orderPriceGroup.goods_price *
                  $parent.datas.exchange_rate
                )
              )
            }}({{
              $fun.RMBNumSegmentation($parent.orderPriceGroup.goods_price)
            }}￥)
          </font>
        </p>
        <p>
          <span>{{ $fanyi('佣金说明') }}({{
            (Number($parent.orderPriceGroup.service_rate) * 100).toFixed(1)
          }}%)</span>
          <font class="otherPrice">
            ${{
              $fun.EURNumSegmentation(
                $fun.ceil(
                  $parent.orderPriceGroup.service_rate_price *
                  $parent.datas.exchange_rate
                )
              )
            }}({{
              $fun.RMBNumSegmentation(
                $parent.orderPriceGroup.service_rate_price
              )
            }}￥)
          </font>
        </p>
        <p>
          <span> {{ $fanyi('附加服务费用') }}</span>
          <font class="otherPrice">
            <el-popover placement="top" trigger="click" popper-class="notHaveBackground">
              <div class="priceDetail">
                <ul class="ohead">
                  <li class="optionName">{{ $fanyi('内容') }}</li>
                  <li>{{ $fanyi('数量') }}</li>
                  <li>$</li>
                  <li>￥</li>
                </ul>
                <ul class="obody" v-for="(item, index) in $parent.numberOfStores.useOption" :key="index">
                  <li class="optionName">{{ item.name_translate }}</li>
                  <li>{{ item.num }}</li>
                  <li>
                    {{
                      $fun.EURNumSegmentation(
                        $fun.ceil(item.total * $parent.datas.exchange_rate)
                      )
                    }}
                  </li>
                  <li>
                    {{ $fun.RMBNumSegmentation($fun.ceil(item.total)) }}
                  </li>
                </ul>
              </div>

              <button slot="reference" class="fujiafuwu" v-show="$parent.orderPriceGroup.option_price != 0">
                {{ $fanyi('明细') }}
              </button>
            </el-popover>
            ${{
              $fun.EURNumSegmentation(
                $fun.ceil(
                  $parent.orderPriceGroup.option_price *
                  $parent.datas.exchange_rate
                )
              )
            }}({{
              $fun.RMBNumSegmentation($parent.orderPriceGroup.option_price)
            }}￥)
          </font>
        </p>
        <p>
          <span> {{ $fanyi('其他费用') }}</span>
          <font class="otherPrice">
            ${{
              $fun.EURNumSegmentation(
                $fun.ceil(
                  $parent.orderPriceGroup.others_price *
                  $parent.datas.exchange_rate
                )
              )
            }}({{
              $fun.RMBNumSegmentation($parent.orderPriceGroup.others_price)
            }}￥)
          </font>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },

  computed: {},
  created() { },
  methods: {},
}
</script>
<style lang="scss">
.notHaveBackground {
  display: flex;
  background-color: #fff !important;
  justify-content: center;
  padding: 20px !important;


  border-radius: 6px;
  border: 1px solid #DFDFDF;
}
</style>
<style lang="scss" scoped="scoped">
@import '../../../../../../css/mixin';

.SpecialNoteVue {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 1400px;
  margin: 0 auto;
  margin-top: 30px;



  .noteBox {
    width: 890px;
    height: 332px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    padding: 30px;
    margin-right: 29px;

    .title {
      height: 28px;

      font-weight: 600;
      font-size: 18px;
      color: #222222;
      line-height: 28px;
      text-align: left;
      margin-bottom: 20px;
    }

    .notetishi {
      width: 405px;
      height: 54px;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 18px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-weight: 400;
      color: #9a9a9a;

      margin-bottom: 10px;

      span {
        display: block;
      }
    }

    .noteInputBox {
      textarea {
        width: 830px;
        height: 160px;
        background: #ffffff;
        padding: 10px;
        font-size: 14px;
        color: #222222;
        line-height: 20px;
        border-radius: 6px;
        border: 1px solid #E8E8E8;
      }

      .disabled {
        background: #f9f9f9;
        border: 1px solid#E2E2E2;
        color: #222222 !important;
      }
    }
  }

  .priceBox {
    width: 480px;
    height: 332px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    padding: 30px;

    h1 {
      height: 28px;
      font-family: Roboto, Roboto;
      font-weight: 600;
      font-size: 18px;
      color: #222222;
      line-height: 28px;
      text-align: left;
    }

    .priceOrder {
      p {
        font-weight: 400;
        display: flex;
        font-size: 14px;
        height: 60px;
        line-height: 20px;
        padding: 20px 0;
        color: #868686;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #e2e2e2;

        span {
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }

        .otherPrice {
          font-weight: 400;
          font-size: 14px;
          color: #222222;
          line-height: 20px;
          text-align: left;
        }
      }
    }
  }
}

.fujiafuwu {
  color: #608bff;
}

.priceDetail {

  min-height: 74px;
  background-color: white;
  z-index: 2;
  overflow: hidden;


  .ohead {
    width: 560px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      font-size: 14px;
      font-weight: 600;
      color: #121212;
    }
  }

  .obody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 0.5px solid #DFDFDF;

    li {
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      height: 50px;

      align-items: center;
      font-size: 14px;
      color: #121212;


      &:nth-child(1) {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 16px;
      }
    }
  }

}
</style>
