<template>
  <div id="couponbox">
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="couponDetailDialogVisible"
      :append-to-body="true" custom-class="couponDetailDialog" width="480px" :center="true" :before-close="handleClose">
      <div class="couponBody" v-loading="loanding">
        <!-- 优惠卷 -->
        <div v-if="type == 'order'" class="couponListContainer">
          <!-- -->
          <div v-for="(item, index) in couponList" :key="index" :class="item.couponChecked
            ? 'couponActiveContainer flexAndCenter'
            : 'couponContainer flexAndCenter'
            ">
            <div class="couponLeft flexAndCenter">
              <div>Coupon</div>
            </div>
            <div class="couponRight">
              <div class="couponNumberText">
                <span>
                  NO：{{ item.number }}
                </span>
                <input type="checkbox" name="" id="" v-model="item.couponChecked" @change="couponChange(item)" />
              </div>
              <div class="couponTitleText">{{ item.title }}</div>
              <div class="couponValidityText">
                {{ $fanyi("有效期") }}：{{ item.free_datetime_start }} —
                {{ item.free_datetime_end }}
              </div>
            </div>
          </div>
          <div v-if="couponList.length == 0">
            <div class="noyouhuijun" v-if="couponList.length == 0">
              <img src="../../../../../../assets/youhuijuan/voucher.svg" alt="" />
              <p>{{ $fanyi("暂时没有可使用的优惠券") }}</p>
            </div>
          </div>
        </div>

        <!-- 代金券 -->
        <div v-else class="couponListContainer">
          <div v-for="(item, index) in arrData" :key="index" :class="item.couponChecked
            ? 'voucherActiveContainer flexAndCenter'
            : 'voucherContainer flexAndCenter'
            ">

            <div class="voucherLeft flexAndCenter">
              <div>Voucher</div>
            </div>
            <div class="couponRight">
              <div class="couponNumberText">
                <span>
                  NO：{{ item.number }}
                </span>
                <input type="checkbox" name="" id="" v-model="item.couponChecked" @change="couponChange(item)" />

              </div>
              <div class="couponTitleText" style="color: #00c18b; margin-bottom: 10px; margin-top: 10px; height: 50px;">
                {{ item.title }}
              </div>
              <div style="margin-bottom: 15px" class="dingdanjine">
                {{ $fanyi("订单金额") }} >
                $ {{ item.restrictions }}
              </div>
              <div class="couponValidityText">
                {{ $fanyi("有效期") }}：{{ item.free_datetime_start }} —
                {{ item.free_datetime_end }}
              </div>
            </div>
          </div>
          <div class="noyouhuijun" v-if="arrData.length == 0">
            <img src="../../../../../../assets/youhuijuan/coupon.svg" alt="" />
            <p>{{ $fanyi("暂时没有可使用的代金券") }}</p>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="promptText">
          {{
            $fanyi(
              "温馨提示：一个订单只能使用一张优惠券，优惠券适用于订单、代金券适用于配送单"
            )
          }}
        </div>
        <div class="btnList flexAndCenter">
          <div @click.stop="confirmed">{{ $fanyi("确认") }}</div>
          <div @click="cancel">
            {{ $fanyi("取消") }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      form: {
        page: 1,
        pageSize: 9999999,

      },
      title: this.$fanyi('选择优惠劵'),
      couponList: [],
      couponDetailDialogVisible: false,
      loanding: false,
      noadata: false,
      type: 'order', // 订单还是配送单
      couponitem: '',// 选中的优惠劵
      amountprice: 0,// 配送单价格
    };
  },


  methods: {

    handleClose() {
      this.couponDetailDialogVisible = false;
    },
    // 获取数据
    getData() {
      this.$api
        .couponGet(this.form)
        .then((res) => {
          this.loanding = false
          if (this.type == 'order') {
            // 优惠劵
            this.couponList = res.data.data.filter((item) => item.type != 30);
          } else {
            // 代金券
            this.couponList = res.data.data.filter((item) => item.type == 30);


          }
          if (this.couponList.length == 0) {
            this.noadata = true
          } else {
            this.noadata = false
          }



          this.couponList.forEach((item) => {
            item.free_datetime_start = item.free_datetime_start.substr(0, 10);
            item.free_datetime_end = item.free_datetime_end.substr(0, 10);
            item.checked = false;
          });



          // this.$forceUpdate();
        })
        .catch((err) => { });

    },

    // 打开优惠劵
    open(ordertype, price, checkeditem) {
      // 订单类型。订单还是配送单
      this.couponDetailDialogVisible = true
      this.type = ordertype
      if (ordertype == 'deliveryorder') {
        this.title = this.$fanyi('选择代金券')
        this.amountprice = Number(price)
      }
      if (this.couponList.length == 0) {
        this.loanding = true
        this.getData()
      }
      if (checkeditem) {
        this.couponList.forEach((arrTypeItem) => {
          if (arrTypeItem.coupon_id == checkeditem.coupon_id) {
            arrTypeItem.couponChecked = true;
            this.couponitem = arrTypeItem
          }
        });
      }
      this.$forceUpdate();
    },

    couponChange(item) {
      // 判断优惠劵是否达到使用额度
      if (this.type == 'deliveryorder') {
        // 代金券使用，配送单金额必须大于代金券使用额度
        if (this.amountprice <= item.restrictions) {
          console.log(this.amountprice <= item.restrictions);
          return
        }
      }
      this.couponList.forEach((arrTypeItem) => {
        if (arrTypeItem.coupon_id !== item.coupon_id) {
          arrTypeItem.couponChecked = false;
        }
      });

      if (item.couponChecked) {
        this.couponitem = item
      } else {
        this.couponitem = ''
      }
      this.$forceUpdate();


    },
    cancel() {
      this.couponDetailDialogVisible = false;
      this.couponList.forEach((arrTypeItem) => {
        arrTypeItem.couponChecked = false;
      });
      this.couponitem = ''
    },

    confirmed() {
      console.log(this.couponitem);

      // 取消清空优惠劵id
      if (this.couponitem !== '') {
        this.$emit('Usecoupons', this.couponitem);
      } else {
        this.$emit('Usecoupons', '');
      }
      this.couponDetailDialogVisible = false;
    },

  },
};
</script>
<style></style>
<style lang="scss" scoped>
/deep/.couponDetailDialog {

  width: 480px;
  min-height: 220px;
  border-radius: 6px;
  background: #ffffff;



  .couponcontent {
    padding-top: 2px;
    width: 100%;
    height: 360px;
    overflow-x: hidden;
    overflow-y: auto;


    display: flex;
    flex-direction: column;

    align-items: center;

    // 滚动条样式
    /* 整个滚动条的宽度 */
    &::-webkit-scrollbar {
      width: 6px;
      /* 滚动条的宽度 */
      /* 滚动条的高度，如果在垂直方向也需要自定义的话 */
    }

    .couponitem {
      width: 400px;

      min-height: 360px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(4, 0, 0, 0.1);
      border-radius: 20px;
      padding: 30px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 10px;
      }

      &:hover {
        cursor: pointer;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        margin-bottom: 35px;

        .Voucher {
          min-width: 140px;
          height: 30px;
          padding: 0px 8px;
          background: #E8EDFA;
          border-radius: 8px;
          font-weight: 500;
          font-size: 14px;
          color: #2052CE;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .coupon {
          min-width: 140px;
          height: 30px;
          padding: 0px 8px;
          background: #F2E8FB;
          border-radius: 8px;
          font-weight: 500;
          font-size: 14px;
          color: #841DD3;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .no {
          font-weight: 500;
          font-size: 14px;
          color: #121212;
          line-height: 20px;
        }
      }

      .content {
        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;
        min-height: 154px;

        h1 {
          width: 100%;
          font-weight: bold;
          font-size: 40px;
          color: #121212;

          min-height: 50px;
          text-align: center;
          margin-bottom: 26px;

          overflow: hidden;
        }

        img {
          width: 36px;
          height: 36px;
        }
      }

      .footer {
        p {
          font-weight: 500;
          font-size: 14px;
          color: #121212;
          line-height: 20px;
          min-height: 20px;

          &:first-child {
            margin-bottom: 15px;
          }
        }
      }
    }

    .noadata {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 360px;

      .img-box {
        width: 160px;
        height: 160px;
        margin-bottom: 36px;

        img {
          width: 100%;
          height: 100%;
        }

      }

      .notext {
        font-weight: normal;
        font-size: 24px;
        color: #121212;
        line-height: 20px;
      }
    }

    .activebgc {
      box-shadow: 0px 0px 10px 0px rgba(4, 0, 0, 0.1);
      border-radius: 20px;
      border: 2px solid #963FDA !important;
    }

    .deliveryListactivebgc {

      box-shadow: 0px 0px 10px 0px rgba(4, 0, 0, 0.1);
      border-radius: 20px;
      border: 2px solid #2052CE !important;
    }

    .IsitavailableActive {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(4, 0, 0, 0.1);
      border-radius: 20px;

      * {
        color: #999999 !important;
      }

      .Voucher {
        background: #F5F5F5 !important;
      }

      cursor: not-allowed !important;
    }
  }

  .el-dialog__header {


    border-bottom: 1px solid #f5f5f5;
    height: 64px;
    display: flex;
    justify-content: flex-start;


    .el-dialog__headerbtn {
      right: 20px;
      top: 20px;

      .el-dialog__close {
        font-weight: 600;
        font-size: 25px;
        color: #000;
      }
    }
  }

  .couponBody {
    padding: 0px;

    overflow: auto;
    max-height: 380px;

    // 滚动条样式
    &::-webkit-scrollbar {
      width: 6px;
    }

    // 代金券
    .couponListContainer {


      .couponContainer,
      .couponActiveContainer,
      .voucherContainer,
      .voucherActiveContainer {
        height: 180px;

        background: url("../../../../../../assets/youhuijuan/white.svg") no-repeat;
        // background-size: 100% 105%;
        // margin-bottom: 30px;
        margin: 0 !important;
        cursor: pointer;
        // background-color: yellow;

        .couponRight {
          height: 136px;



          .couponNumberText {
            width: 280px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #222;

            input[type='checkbox'] {
              background-color: #fff;
              -webkit-appearance: none;
              border: 1px solid #c0c4cc;
              border-radius: 4px;
              outline: none;


              &:hover {
                cursor: pointer;
              }

            }

            input[type='checkbox']:checked {
              background: #FF730B;

              border: 1px solid #FF730B;

              &::after {
                height: 4px;
                width: 8px;

              }
            }

          }

          .couponTitleText {
            color: #222222;
            font-weight: 600;
            width: 260px;
            height: 80px;
            display: flex;
            align-items: center;
            line-height: 31px;
            font-size: 30px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            margin: 10px 0px;

            color: #608bff;
            font-size: 36px;
          }

          .couponValidityText {
            color: #222;
            font-size: 12px;
            // margin-top: 50px;
            margin-bottom: 10px;
          }
        }
      }

      .couponActiveContainer {
        background: url("../../../../../../assets/youhuijuan/blue.svg") no-repeat !important;

        .couponRight {
          .couponNumberText {
            color: #fff !important;
          }

          .couponTitleText {
            color: #fff !important;
          }

          .couponValidityText {
            color: #fff !important;
          }
        }
      }

      .couponLeft {
        margin: 15px 20px 12px 22px !important;
        border-radius: 4px;
      }

      .voucherActiveContainer {
        background: url("../../../../../../assets/newImg/order/green.svg") no-repeat !important;

        .couponRight {
          height: 136px;

          .couponNumberText {
            color: #fff !important;

          }

          .couponTitleText {
            color: #fff !important;
          }

          .dingdanjine {
            color: #fff !important;
          }

          .couponValidityText {
            color: #fff !important;
          }
        }
      }

      .voucherLeft {
        border-radius: 4px;
        border: 2px solid #00c18b !important;

        div {
          color: #00c18b !important;
          min-width: 100px !important;
        }
      }

      .couponLeft,
      .voucherLeft {
        margin: 15px 20px 12px 22px;
        width: 64px;
        background: #ffffff;
        border: 2px solid #608bff;
        justify-content: center;
        height: 136px;

        div {
          color: #608bff;
          font-size: 24px;
          font-weight: 600;
          transform: rotateZ(270deg);
          min-height: 94px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 6px;
          min-width: 100px;
        }
      }

      .couponContainer:nth-child(2n),
      .couponActiveContainer:nth-child(2n),
      .voucherContainer,
      .voucherActiveContainer {
        margin-right: 0;
      }


    }
  }

  .dialog-footer {

    padding-bottom: 10px;

    .promptText {
      color: #999999;
      font-size: 12px;
      text-align: left;
      margin-bottom: 20px;
    }

    .btnList {
      display: flex;
      justify-content: center;

      div {
        width: 120px;
        height: 40px;
        background: #ff730b;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
        border: 1px solid #e2e2e2;
        color: #fff;
        border-radius: 6px;
      }

      div:first-child {
        margin-right: 40px;
        border: 1px solid #ff730b;
      }

      div:last-child {
        color: #222222;
        background: #fff;
      }
    }
  }

  // 没有数据
  .noyouhuijun {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
      width: 128px;
      height: 128px;
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }



  // .el-dialog__body {
  //   padding: 0px;
  // }


}
</style>
