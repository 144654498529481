<template>
  <!-- 替换链接组件 -->
  <div class="Replacementlinkcomponents">
    <span class="tihuanFont" @click="replaceGoods">
      {{
        $fanyi('替换产品')
      }}
    </span>
    <Dialog ref="Replacedialog" :config="config">
      <div class="Replacebox"> <!-- 新商品 -->
        <div class="goodsBlock oldGoods">
          <h2>
            {{
              goodsToBeReplaced.num > goodsToBeReplaced.confirm_num
                ? $fanyi('库存不足商品')
                : $fanyi('当前商品')
            }}
          </h2>
          <div class="goodsArrTable">
            <div class="arrtableHead">
              <div class="goodsshop">
                {{ $fanyi('店铺') }}
              </div>
              <div class="goodsArrTdimg ">
                {{ $fanyi('商品图片') }}
              </div>
              <div class="goodsArrTdproduct">
                {{ $fanyi('产品') }}
              </div>
              <div class="goodsArrTdStats">
                {{ $fanyi('属性') }}
              </div>
              <div class="goodsArrTdnum">
                {{ $fanyi('数量') }}
              </div>
              <div class="goodsArrTdNumberinstock">
                {{ $fanyi('在库数') }}
              </div>
              <div class="goodsArrTdprice">
                {{ $fanyi('单价') }}
              </div>
              <div class="goodsArrTdfreight"></div>
              <div class="goodsArrTdcaozuo"></div>
            </div>
            <div class="arrtableBody">
              <div class="arrtableRow">
                <div class="goodsshop">
                  {{ shopName }}
                </div>
                <div class="goodsArrTdimg">
                  <el-popover placement="right" trigger="hover">
                    <img :src="goodsToBeReplaced.pic" alt="" style="width: 300px; height: 300px" />
                    <el-image :src="goodsToBeReplaced.pic" slot="reference">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                </div>
                <div class="goodsArrTdproduct">
                  <el-popover placement="bottom" width="400" trigger="hover">
                    <span>{{ goodsToBeReplaced.goods_title }}</span>
                    <div slot="reference">
                      <div class="goods_title">
                        {{ goodsToBeReplaced.goods_title }}
                      </div>
                    </div>
                  </el-popover>
                </div>
                <div class="goodsArrTdStats">
                  <el-popover placement="bottom" width="" trigger="hover">
                    <p v-for="(detailItem,
                      detailIndex) in goodsToBeReplaced.detail" :key="'goodsdetailIndex' + detailIndex">
                      {{ detailItem.key }}:{{ detailItem.value }}
                    </p>

                    <div slot="reference" class="tdProinSpan">
                      <p v-for="(detailItem,
                        detailIndex) in goodsToBeReplaced.detail" :key="'goodsdetailIndex' + detailIndex">
                        {{ detailItem.key }}:{{ detailItem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
                <div class="goodsArrTdnum">
                  {{ goodsToBeReplaced.num }}
                </div>
                <div class="goodsArrTdNumberinstock">
                  {{ goodsToBeReplaced.confirm_num }}
                </div>
                <div class="goodsArrTdprice">
                  <span>
                    ${{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsToBeReplaced.confirm_price *
                          exchange_rate
                        )
                      )
                    }}</span>
                  <span>({{
                    $fun.RMBNumSegmentation(goodsToBeReplaced.confirm_price)
                  }}￥)</span>
                </div>
                <!-- 代替换商品表格的后两列凑数用 -->
                <div class="goodsArrTdfreight"></div>
                <div class="goodsArrTdcaozuo"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 旧商品 -->
        <div class="goodsBlock newGoods">
          <h2>{{ $fanyi('可选择替换的商品') }}</h2>
          <div class="goodsArrTable">
            <div class="arrtableHead">
              <div class="goodsshop">
                {{ $fanyi('店铺') }}
              </div>
              <div class="goodsArrTdimg">
                {{ $fanyi('商品图片') }}
              </div>
              <div class="goodsArrTdproduct">
                {{ $fanyi('产品') }}
              </div>
              <div class="goodsArrTdStats">
                {{ $fanyi('属性') }}
              </div>
              <div class="goodsArrTdnum">
                {{ $fanyi('数量') }}
              </div>
              <div class="goodsArrTdNumberinstock">
                {{ $fanyi('在库数') }}
              </div>
              <div class="goodsArrTdprice">
                {{ $fanyi('单价') }}
              </div>
              <div class="goodsArrTdfreight">
                {{ $fanyi('国内运费') }}
              </div>
              <div class="goodsArrTdcaozuo"></div>
            </div>
            <div class="arrtableBody" v-if="SustituciónDeBienes.length > 0">
              <div class="arrtableRow" v-for="(goodsItem, goodsIndex) in SustituciónDeBienes"
                :key="'tihuanGoods' + goodsIndex">
                <div class="goodsshop">
                  {{ goodsItem.shop_name }}
                </div>
                <div class="goodsArrTdimg">
                  <el-popover placement="right" trigger="hover">
                    <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />
                    <el-image :src="goodsItem.pic" slot="reference">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                </div>
                <div class="goodsArrTdproduct">
                  <el-popover placement="bottom" width="400" trigger="hover">
                    <span>{{ goodsItem.goods_title }}</span>
                    <div slot="reference">
                      {{ goodsItem.goods_title }}
                    </div>
                  </el-popover>
                </div>
                <div class="goodsArrTdStats">
                  <el-popover placement="bottom" width="" trigger="hover">
                    <p v-for="(detailItem, detailIndex) in JSON.parse(
                      goodsItem.detail
                    )" :key="'goodsdetailIndex' + detailIndex">
                      {{ detailItem.key }}:{{ detailItem.value }}
                    </p>

                    <div slot="reference" class="tdProinSpan">
                      <p v-for="(detailItem, detailIndex) in JSON.parse(
                        goodsItem.detail
                      )" :key="'goodsdetailIndex' + detailIndex">
                        {{ detailItem.key }}:{{ detailItem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
                <div class="goodsArrTdnum">
                  {{ goodsItem.num }}
                </div>
                <div class="goodsArrTdNumberinstock">
                  {{ goodsItem.confirm_num }}
                </div>
                <div class="goodsArrTdprice">
                  <span>
                    $
                    {{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsItem.confirm_price * exchange_rate
                        )
                      )
                    }}</span>
                  <span>({{
                    $fun.RMBNumSegmentation(goodsItem.confirm_price)
                  }}￥)</span>
                </div>
                <div class="goodsArrTdfreight">
                  {{
                    $fun.EURNumSegmentation(goodsItem.confirm_dicker_freight)
                  }}
                </div>
                <div class="goodsArrTdcaozuo">
                  <el-button type="primary" class="chioseBtn" @click="confirmeElReemplazo(goodsItem.id)">{{
                    $fanyi('选择该商品') }}</el-button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import Dialog from '../../../../../../components/public/Dialog.vue'
export default {
  props: {
    goodsToBeReplaced: {
      default: {},
      type: Object,
    },
    exchange_rate: {
      required: true,
    },
    SustituciónDeBienes: {
      default: () => {
        return []
      },
      type: Array,
    },
    shopName: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      config: {
        top: '20vh',
        width: '1400px',
        title: this.$fanyi('替换商品'),
        btnTxt: ['删除', '取消'],
      },
    }
  },
  components: { Dialog },
  computed: {},
  created() {


  },
  mounted() { },
  methods: {
    // 关闭弹窗事件
    closeDia() {
      this.$refs.Replacedialog.cancel()
    },
    // 打开替换商品弹窗
    replaceGoods() {
      this.$refs.Replacedialog.open(
        '',
        () => { },
        () => {

        }
      )
    },
    // 确认选择该商品进行替换
    confirmeElReemplazo(goodsId) {
      this.$api
        .replaceGoods({
          replace_id: goodsId,
        })
        .then((res) => {
          if (res.code != 0) return this.$message.error(res.meg)
          // 重新获取数据
          this.$parent.pageLoad()
          this.$refs.Replacedialog.cancel()
        })
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../css/mixin';

.Replacementlinkcomponents {
  .tihuanFont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 146px;

    height: 100%;
    padding: 0 15px;
  }
}

/deep/.el-dialog__header {

  padding: 0px 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  height: 68px;
  border-bottom: 1px solid #E2E2E2;
  color: #222222 !important;


}

/deep/.el-dialog {

  max-height: 869px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e2e2e2;

}

/deep/.el-dialog__footer {
  display: none;
}

/deep/.el-dialog__body {
  padding: 30px 20px !important;
}

.Replacebox {

  .goodsBlock {


    >h2 {
      font-size: 16px;

      max-width: 260px;
      height: 40px;
      background: #FFF2E8;
      border-radius: 4px;
      font-weight: 500;
      text-align: center;
      font-size: 16px;
      color: #ff730b;
      line-height: 40px;
      margin-bottom: 20px;
    }

    // 表格区域
    .goodsArrTable {
      width: 1360px;

      margin: 0 auto;

      .noData {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .arrtableHead {
        height: 60px;
        background: #F6F6F6;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          color: #222222;
          text-align: center;
        }

        .goodsshop {

          flex: 0 0 120px;
        }

        .goodsArrTdimg {
          flex: 0 0 80px;
        }

        .goodsArrTdproduct {
          flex: 0 0 160px;


        }

        .goodsArrTdStats {
          flex: 0 0 140px;
        }

        .goodsArrTdnum {
          flex: 0 0 80px;
        }

        .goodsArrTdNumberinstock {
          flex: 0 0 90px;
        }

        .goodsArrTdprice {
          flex: 0 0 160px;
        }

        .goodsArrTdfreight {
          flex: 0 0 160px;
        }

        .goodsArrTdcaozuo {
          flex: 0 0 140px;
        }

      }

      .arrtableBody {
        max-height: 220px;
        overflow: auto;
        padding: 10px 0 30px;


        &::-webkit-scrollbar {
          width: 6px;
        }

        .arrtableRow {
          display: flex;
          align-items: center;
          justify-content: space-between;

          background-color: #fff5ee;
          border-radius: 6px;
          padding: 20px;

          div {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #222222;
            line-height: 20px;
            justify-content: center;
          }

          .goodsshop {

            flex: 0 0 120px;
          }

          .goodsArrTdimg {
            flex: 0 0 80px;

            /deep/.el-image {
              border-radius: 6px;
            }
          }

          .goodsArrTdproduct {
            flex: 0 0 160px;

            .goods_title {
              height: 80px;
              width: 160px; // 四行文本溢出
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              overflow: hidden;

            }
          }

          .goodsArrTdStats {
            flex: 0 0 140px;

            .tdProinSpan {
              display: block;
              height: 80px;
              width: 140px; // 四行文本溢出
              overflow-y: scroll; // 滚动条样式

              &::-webkit-scrollbar {
                width: 6px;
              }
            }
          }

          .goodsArrTdnum {
            flex: 0 0 80px;
          }

          .goodsArrTdNumberinstock {
            flex: 0 0 90px;
          }

          .goodsArrTdprice {
            flex: 0 0 160px;
          }

          .goodsArrTdfreight {
            flex: 0 0 160px;
          }

          .goodsArrTdcaozuo {
            flex: 0 0 140px;
          }
        }
      }


    }

    &.oldGoods {
      border-bottom: dashed 1px #E2E2E2;
      margin-bottom: 30px;
    }

    &.newGoods {
      h2 {
        max-width: 260px;
        height: 40px;
        background: #eff4ff;
        border-radius: 4px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #608bff;
      }

      .arrtableRow {
        background-color: white !important;
        border-bottom: solid 1px#E2E2E2;
      }
    }

    .chioseBtn {
      width: 140px;
      height: 40px;
      background: #FF730B;
      border-radius: 4px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
