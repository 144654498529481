<template>
  <div class="addmain">

    <!-- 导航栏 -->
    <nav>
      <span @click="mainTitleclick('cart')" :class="form.type == 'cart' ? 'active' : ''">{{
        $fanyi('购物车')
      }}</span><span @click="mainTitleclick('inventory')" :class="form.type == 'inventory' ? 'active' : ''">{{
          $fanyi('商品库') }}</span>
    </nav>
    <div class="content" v-loading="loading">
      <!-- 表头 -->
      <div class="head">
        <ul>
          <li class="chexk-box">
            <input type="checkbox" name="" id="" v-model="checked" @change="allChoice" />
            {{ $fanyi('全选') }}
          </li>
          <li class="goods">{{ $fanyi('产品') }}</li>
          <li class="detail">{{ $fanyi('详情') }}</li>
          <li class="price">{{ $fanyi('单价') }}</li>
          <li class="Num">{{ $fanyi('数量') }}</li>
          <li class="totalprice">{{ $fanyi('总价') }}</li>
        </ul>
      </div>
      <!-- 追加商品显示 -->
      <div class="orderAddGoodsBox" v-if="lists.length != 0">
        <ul v-for="(listItem, listIndex) in lists" :key="listIndex">
          <li class="checkBox">
            <div class="check">
              <input type="checkbox" name="" id="" :checked="listItem.checked" @change="
                listItem.checked = !listItem.checked
              oddChoice(listIndex)
                " />
            </div>
            <div>{{ listIndex + 1 }}</div>
            <el-popover placement="right" trigger="hover">
              <img :src="listItem.pic" alt="" style="width: 288px; height: 298px" />

              <img class="goodimg" @click="
                $fun.toCommodityDetails({
                  goods_id: listItem.goods_id,
                  shop_type: listItem.shop_type,
                })
                " :src="listItem.pic" alt="" slot="reference" />
            </el-popover>

          </li>
          <li class="goods">
            <div class="text">
              <el-popover placement="bottom" width="400" trigger="hover">
                <p>
                  {{ listItem.goods_title }}
                </p>

                <p slot="reference">
                  <router-link class="goodsTitle" :to="'/productDetails?goods_id=' + listItem.goods_id">{{
                    listItem.goods_title }}
                  </router-link>
                </p>
              </el-popover>
              <input class="remark" type="text" :placeholder="$fanyi('备注栏')" v-model="listItem.user_postscript"
                @blur="editCartRemark(listItem)" />
            </div>
          </li>
          <li class="detail">
            <el-popover placement="bottom" trigger="hover">
              <div class="goodsDetailAll">
                <div class="goodsDetailAllOneBox" v-for="(detailItem, detailIndex) in listItem.detail"
                  :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                  <h1 class="detailTitle">{{ detailItem.key }}:</h1>
                  <p>
                    <span class="detailBody">{{ detailItem.value }}</span>
                  </p>
                </div>
              </div>

              <div slot="reference" class="detailBox">
                <p v-for="(detailItem, detailIndex) in listItem.detail" :key="detailIndex"
                  :title="detailItem.key + ':' + detailItem.value">
                  {{ detailItem.key }}:{{ detailItem.value }}
                </p>
              </div>
            </el-popover>
          </li>
          <li class="price">
            <div class="price-box" v-if="listItem.price_range">
              <!-- 单价 -->
              <p class="Unitprice" style="text-align: center">
                <span> $ {{
                  $fun.EURNumSegmentation(
                    $fun.ceil((listItem.univalence != 0
                      ? listItem.univalence
                      : Number(listItem.price)) * $store.state.exchangeRate)
                  )
                }}
                </span>
                <span> ({{
                  $fun.RMBNumSegmentation(
                    (listItem.univalence != 0
                      ? listItem.univalence
                      : Number(listItem.price))
                  )
                }}￥) </span>
              </p>
              <div v-if="listItem.price_range && !listItem.price_range.priceMin">
                <p v-for="(priceItem, priceIndex) in listItem.price_range" :key="priceIndex" v-show="panduanxianshi(listItem.num, listItem.price_range) ==
                  priceIndex
                  ">
                  > =<b> {{ priceItem.startQuantity }}</b> {{ $fanyi('件') }}
                  {{ $fun.RMBNumSegmentation(priceItem.price) }}¥
                </p>
              </div>
              <!-- 最大值-最小值 -->
              <p v-else>
                {{ listItem.price_range.priceMax }}~{{
                  listItem.price_range.priceMin
                }}
              </p>
            </div>
            <div class="price-box" v-else>
              <p class="Unitprice" style="text-align: center">
                <span> $ {{
                  $fun.EURNumSegmentation(
                    $fun.ceil((listItem.univalence != 0
                      ? listItem.univalence
                      : Number(listItem.price)) * $store.state.exchangeRate)
                  )
                }}
                </span>
                <span> ({{
                  $fun.RMBNumSegmentation(
                    (listItem.univalence != 0
                      ? listItem.univalence
                      : Number(listItem.price))
                  )
                }}￥) </span>
              </p>
            </div>
          </li>
          <!-- 选择数量 -->
          <li class="Num">
            {{ listItem.num }}
          </li>
          <!-- 总价 -->
          <li class="totalprice">
            <span>
              ${{
                $fun.EURNumSegmentation(
                  $fun.ceil(listItem.total * $store.state.exchangeRate)
                )
              }}
              ({{ $fun.RMBNumSegmentation(listItem.total) }}¥)
            </span>
          </li>
        </ul>

      </div>
      <!-- 购物车无商品 -->
      <div class="cart" v-else>
        <div>
          <img src="../../../../../../assets/img/gwcc.svg" alt="" />
          <div class="nav">
            <p>
              {{
                form.type == 'inventory'
                  ? $fanyi('商品库为空')
                  : $fanyi('购物车为空')
              }}
            </p>
            <p>
              <router-link to="/"> {{ $fanyi('去购物') }} |</router-link>
              <router-link to="/user/favoriteproduct">
                {{ $fanyi('收藏夹') }}</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <input type="checkbox" name="" id="" :checked="checked" @change="
        checked = !checked
      allChoice()
        " /><b @click="
          checked = !checked
        allChoice()
          ">
        {{ $fanyi('全选') }}</b>

      <div class="total shuliang">
        <div class="goodsNum">
          <p>
            {{ $fanyi('数量总计') }}： <span>{{ isTotal }}</span>
          </p>
        </div>
      </div>

      <div class="total">
        <p>
          {{ $fanyi('总价') }}：

          <span class="price">
            $
            {{
              $fun.EURNumSegmentation(
                $fun.ceil(totalPrice * $store.state.exchangeRate)
              )
            }}
          </span>

        </p>
        <p>
          <span>
            <span class="RMBTotal">
              <span style="margin-right: -4px; font-size: 14px">￥</span>
              {{ $fun.RMBNumSegmentation($fun.ceil(totalPrice)) }}
            </span>
          </span>

        </p>
      </div>

      <button :disabled="idArr.length == 0" :class="{ btn: idArr.length > 0 }" @click="zhuijia">
        {{ $fanyi('追加') }}
      </button>
    </div>
  </div>
</template>
<script>


export default {
  data() {
    return {
      form: {
        type: 'cart',
        page: 1,
        pageSize: 99,
      },
      loading: false,

      checked: false, //全选
      type: 0, // 类型
      num: 0, //
      isTotal: 0, // 选中的商品数
      totalPrice: 0, // 选中的商品总价
      lists: [], // 数据
      idArr: [], // 选中的ID


    }
  },

  created() {
    this.getAddOrderList()
  },

  methods: {
    // 判断显示那个价格区间
    panduanxianshi(num, arr) {
      if (num < arr[0].startQuantity) {
        return 0
      }
      let s = 0
      arr.forEach((item, index) => {
        if (num >= arr[index].startQuantity) {
          s = index
        }
      })
      return s
    },
    // 点击切换
    mainTitleclick(val) {
      this.form.type = val
      this.checked = false
      this.totalPrice = 0
      this.isTotal = 0
      this.$forceUpdate()
      this.getAddOrderList()
    },
    // 获取追加订单商品列表
    getAddOrderList() {
      this.loading = true
      var datas = {
        type: this.form.type,
        page: this.form.page,
        pageSize: this.form.pageSize,
      }
      if (this.form.type == 'cart') {
        datas.not_cart_id = []
      } else {
        datas.not_inventory_id = []
      }
      this.$parent.$parent.newList.forEach((shopItem) => {
        shopItem.list.forEach((goodsItem) => {
          if (this.form.type == 'cart' && !!goodsItem.cart_id) {
            datas.not_cart_id.push(goodsItem.cart_id)
          } else if (
            this.form.type == 'inventory' &&
            !!goodsItem.inventory_id
          ) {
            datas.not_inventory_id.push(goodsItem.inventory_id)
          }
        })
      })
      if (this.form.type == 'cart') {
        datas.not_cart_id = datas.not_cart_id.join(',')
      } else {
        datas.not_inventory_id = datas.not_inventory_id.join(',')
      }
      this.$api.orderAddGoodsList(datas).then((res) => {
        this.loading = false
        if (res.code != 0) return
        this.lists = res.data
        if (res.data.length == 0) return false

        this.tidyData()
      })
    },

    // 整理数据
    tidyData() {
      this.lists.forEach((item) => {
        // 商品总数
        item.checked = false // 商品选择
        item.univalence = 0 // 显示单价
        if (item.num == 0) item.num = 1 //如果数量是0，就改成1
        item.total = this.$fun.ceil(item.price * item.num)


      })

    },


    // 单选
    oddChoice(i) {
      this.isCheckedAll()
      this.commodityTotal()
      this.$forceUpdate()
    },

    // // 全选
    allChoice() {
      if (this.checked) {
        this.lists.forEach((item, index) => {
          item.checked = true
        })
      } else {
        this.lists.forEach((item, index) => {
          item.checked = false
        })
      }
      this.commodityTotal()
      this.$forceUpdate()
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.lists.every((item, index) => item.checked == true)
      if (flag == true) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    // // 选中商品数量总数、总价
    commodityTotal() {
      this.totalPrice = 0
      this.isTotal = 0
      this.idArr = []
      this.lists.forEach((item, i) => {
        if (item.checked == true) {
          this.isTotal += item.num
          this.totalPrice += item.total - 0
          this.idArr.push(item.cart_id)
        }
      })

    },


    // 备注商品信息
    editCartRemark(item) {
      if (item.beikao == '') return false
      let datas = {
        cart_id: item.cart_id,
        user_postscript: item.user_postscript,
      }
      this.$api.goodsCartPostscript(datas).then((res) => {
        if (res.code != 0) return false
        this.$message.success('保存成功')
      })
    },

    // 追加到订单详情页
    zhuijia() {
      let addData = {
        newList: [],
      };
      let goodsNum = 0;
      this.lists.forEach((goodsItem) => {
        if (goodsItem.checked) {
          // 计算追加的商品数量
          goodsNum++;
          // Check if shop already exists in newList
          const existingShop = addData.newList.find(item => item.shop_id === goodsItem.shop_id);
          if (!existingShop) {
            addData.newList.push({
              shop_id: goodsItem.shop_id,
              shop_name: goodsItem.shop_name,
              list: [goodsItem],
            });
          } else {
            existingShop.list.push(goodsItem);
          }
        }
      });
      // 如果追加的商品和订单里的商品数量加起来超过一百就不让他追加
      if (this.$parent.$parent.numberOfStores.goods + goodsNum > 60) {
        return this.$message.warning(this.$fanyi("一个订单最多添加60个商品"));
      }
      this.$parent.$parent.addTo(addData);
      // 关闭弹窗
      this.$parent.$parent.showCart = false;
    },
  },

}
</script>

<style lang="scss" scoped>
@import '../../../../../../css/mixin';





.addmain {
  width: 100%;
  margin: 0px auto 0px;
  background: #ffffff;
  border-radius: 6px;
  padding: 0px 0 20px;
  overflow: hidden;

  nav {
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E2E2E2;
    padding: 0 20px;

    >span {
      // width: 130px;
      padding: 10px;
      height: 68px;
      font-family: Roboto, Roboto;
      font-weight: 500;
      font-size: 18px;
      color: #222222;
      line-height: 28px;
      text-align: left;
      border-bottom: 2px solid transparent;
      transition: 0.3s;
      cursor: pointer;
      margin-right: 30px;
      padding-top: 25px;

      &.active {
        padding: 10px;
        padding-top: 20px;
        font-family: Roboto, Roboto;
        font-weight: 500;
        font-size: 18px;
        color: #222222;
        line-height: 28px;
        text-align: left;
        color: #ff730b;
        border-bottom: 2px solid #ff730b;
      }
    }
  }

  .content {
    padding: 0 20px;
    padding-bottom: 100px;

    .head {
      width: 100%;
      height: 60px;
      background: #F6F6F6;
      border-radius: 6px;
      margin-top: 20px;
      position: sticky;
      padding: 0 20px;

      z-index: 4;

      ul {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        height: 60px;
        color: #222222;
        line-height: 20px;
        margin: none;
        color: #222222;
        justify-content: space-between;
        align-items: center;
        background-color: #f6f6f6;
        border-radius: 6px;

        li {

          font-weight: 500;
          font-size: 14px;
          color: #222222;
          line-height: 20px;
          text-align: center;

        }

        .chexk-box {
          flex: 0 0 160px;
          display: flex;
          align-items: center;

          input[type='checkbox'] {
            background-color: #fff;
            -webkit-appearance: none;
            border: 1px solid #c0c4cc;
            border-radius: 4px;
            outline: none;
            margin-right: 10px;

            &:hover {
              cursor: pointer;
            }
          }

          input[type='checkbox']:checked {
            background: #FF730B;

            border: 1px solid #FF730B;

            &::after {
              height: 4px;
              width: 8px;

            }
          }
        }

        .goods {
          flex: 0 0 300px;
        }

        .detail {
          flex: 0 0 220px;
        }

        .price {
          flex: 0 0 140px;
        }

        .Num {
          flex: 0 0 140px;
        }

        .totalprice {
          flex: 0 0 140px;
        }


      }
    }

    .orderAddGoodsBox {
      padding: 0 20px;
      max-height: 600px;
      overflow-y: scroll;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 120px;
        border-bottom: 1px dashed #E2E2E2;

        li {
          display: flex;
          justify-content: center;
        }

        .checkBox {
          flex: 0 0 160px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          input[type='checkbox'] {
            background-color: #fff;
            -webkit-appearance: none;
            border: 1px solid #c0c4cc;
            border-radius: 4px;
            outline: none;


            &:hover {
              cursor: pointer;
            }
          }

          input[type='checkbox']:checked {
            background: #FF730B;

            border: 1px solid #FF730B;

            &::after {
              height: 4px;
              width: 8px;

            }
          }

          .goodimg {
            width: 80px;
            height: 80px;
            background: #ededed;
            border-radius: 6px;
          }
        }

        .goods {
          flex: 0 0 300px;

          .goodsTitle {
            height: 40px;
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 14px;
            color: #222222;
            line-height: 20px;
            text-align: left;
            // 文本两行移出隐藏
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            margin-bottom: 10px;

            &:hover {
              cursor: pointer;
              color: #FF730B;
            }

          }

          .remark {
            width: 300px;
            height: 30px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid rgba(96, 139, 255, 0.3);
            padding-left: 10px;

          }
        }

        .detail {
          flex: 0 0 220px;

          .detailBox {
            text-align: left;
            font-size: 14px;
            color: #000000;
            line-height: 19px;

            margin-left: 20px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            p {
              width: 220px;
              text-align: left;
              font-weight: 400;
            }
          }
        }

        .price {
          flex: 0 0 140px;

          .Unitprice {
            font-weight: 400;
            font-size: 14px;
            color: #222222;
            line-height: 20px;
            text-align: center;
            margin-bottom: 5px;
          }
        }

        .Num {
          flex: 0 0 140px;
          font-weight: 400;
          font-size: 14px;
          color: #222222;
        }

        .totalprice {
          flex: 0 0 140px;
          font-weight: 400;
          font-size: 14px;
          color: #FF730B;
          line-height: 20px;
        }
      }
    }

    .cart {
      padding: 50px;
      text-align: center;
      overflow: hidden;

      >div {
        margin: 0 auto;
        width: 440px;
        overflow: hidden;
      }

      img {
        float: left;
        //   margin-left: 280px;
      }

      .nav {
        float: left;
        margin: 50px 0 0 30px;

        p {
          font-size: 20px;
          line-height: 50px;

          a {
            color: $homePageSubjectColor;
            font-size: 16px;
          }
        }
      }
    }
  }

  .foot {
    width: 1400px;
    background: #FFFFFF;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #E2E2E2;
    padding-left: 40px;
    padding-right: 30px;
    box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100px;
    font-weight: 700;
    z-index: 99;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 6px;

    input[type='checkbox'] {
      background-color: #fff;
      -webkit-appearance: none;
      border: 1px solid #c0c4cc;
      border-radius: 4px;
      outline: none;
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    input[type='checkbox']:checked {
      background: #FF730B;

      border: 1px solid #FF730B;

      &::after {
        height: 4px;
        width: 8px;

      }
    }

    b {
      cursor: pointer;
      font-size: 14px;
      color: #888888;
      line-height: 19px;

      user-select: none;

      &.del {
        font-size: 14px;
        color: $homePageSubjectColor;
        line-height: 19px;
        margin-left: 40px;
      }
    }

    .total {
      // margin: 0 0 0 auto;
      font-weight: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding-left: 0px;

      &.shuliang {
        margin: 0 40px 0 auto;
      }

      p {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 26px;

        .price {
          color: #ff730b;
          font-weight: bold;
          font-size: 24px;
          max-width: 246px;
          margin-right: 10px;

          span {
            font-size: 24px;
          }
        }

        .RMBTotal {
          font-weight: 400;
          font-size: 14px;
        }
      }

      span {
        font-size: 14px;
        font-weight: 700;
        // margin: 0 10px;
      }

      .goodsNum {
        margin: 0 0 0 250px;
        position: relative;
        line-height: 30px;
        font-size: 14px;
      }
    }

    i {
      margin: 0 20px;
    }

    button {
      width: 200px;
      height: 60px;
      background: #ff730b;
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      line-height: 60px;

      cursor: not-allowed;
      margin-left: 40px;
    }

    .btn {
      background: #ff730b;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

// 规格弹层
.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      margin-bottom: 5px;
    }

    p {
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}
</style>
