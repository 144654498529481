<template>
  <div class="shippingInformationPage ">
    <div class="shippingInformationTable">
      <ul class="shippingInformationTableHeader flexAndCenter">
        <li>{{ $fanyi('配送单号') }}</li>
        <li>{{ $fanyi('提出日期') }}</li>
        <li>{{ $fanyi('发货日期') }}</li>
        <li>{{ $fanyi('包含订单') }}</li>
        <li>{{ $fanyi('物流单号') }}</li>
        <li>{{ $fanyi('配送单状态') }}</li>
      </ul>
      <ul class="shippingInformationTableFooter flexAndCenter" v-for="(item, index) in list" :key="index">
        <li class="distributionBillNo" @click="
          $fun.toPage(
            '/deliveryDetails?type=purchase&order_sn=' + item.porder_sn
          )
          ">
          {{ item.porder_sn }}
        </li>
        <li>{{ item.created_at }}</li>
        <li>{{ item.datetime_delivery ? item.datetime_delivery : '-----' }}</li>
        <li class="order_sn">
          <p v-for="(i, index) in item.order_sn " :key="index">{{ i }}</p>
        </li>
        <li class="Ordernumber">
          {{ item.express_no ? item.express_no : $fanyi('暂无单号') }}
        </li>
        <li>{{ $fanyi(item.status_name) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shippingInformation',
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {}
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>
.shippingInformationPage {
  margin-top: 30px;
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  justify-content: center;
  min-height: 200px;

  margin: 0 auto;
  padding: 30px;

  .shippingInformationTable {
    width: 1340px;
    min-height: 56px;

    border: 1px solid #e2e2e2;
    border-right: 0px;

    .shippingInformationTableHeader {
      height: 56px;
      background: #fff2e8;
      // border: 1px solid #e2e2e2;
      width: 1338px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #222222;
      border-right: 1px solid #e2e2e2;
      height: 56px;
      box-sizing: border-box;

      li {
        color: #222222;
        font-size: 14px;
        width: 223px;
        text-align: center;
        line-height: 60px;
        overflow: hidden;
        box-sizing: border-box;
      }
    }

    .shippingInformationTableFooter {
      border-right: 0px;
      min-height: 60px;
      border-bottom: 1px solid #e2e2e2;


      &:last-child {
        border-bottom: 0px;
      }

      li {
        color: #222222;
        font-size: 14px;
        width: 223px;
        height: 60px;
        overflow-y: scroll;

        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #e2e2e2;






      }

      .order_sn {
        display: block;
        padding: 10px 0px !important;

        p {
          border: none;
          width: 100%;
          // height: 100%;
          line-height: 18px;
        }
      }

      .distributionBillNo {
        font-weight: 400;
        color: #ff730b;

        cursor: pointer;
      }

      .distributionBillNo:hover {
        font-weight: 400;
        color: #ff730b;
        text-decoration: underline;
        cursor: pointer;
      }

      .Ordernumber {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #608bff;
      }

      .Ordernumber:hover {
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #608bff;
        text-decoration: underline;
      }
    }
  }
}
</style>
