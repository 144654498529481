<template>
  <div class="placingSuccessfullyPageBox">
    <HomePageTop></HomePageTop>
    <div class="placingSuccessfullyPage">
      <div class="orderDetailBox">
        <!-- 成功 -->
        <div class="confirmation">
          <div class="confirmationH1">
            <img src="../../../../../../assets/newImg/order/quotation.png" alt="" />
            <div>
              {{ $fanyi('正在进行的报价') }}
            </div>
          </div>
          <p>{{ $fanyi('感谢您的下单，您的订单已经成功提出。') }}</p>
          <p>
            {{
              $fanyi(
                '在24小时之内，您将收到一封电子邮件，里边包含该订单的国际运输方式和预估运输费用。'
              )
            }}
          </p>

        </div>
        <!-- 提示 -->

        <!-- 订单摘要 -->
        <div class="goodsBox" :class="{ GoodsArrAll: showGoodsArrAll }">
          <h1 @click="getBuyGoodsArr">
            {{ $fanyi('订单摘要') }}
            <span class="goodsBoxtishi">{{ $fanyi('订单号') }}:{{ order_sn }}</span>
          </h1>
          <div class="goodsArr">
            <div class="goodsHead goodsTr">
              <div class="goodsTd goodshe goodsName" style="margin-left: 20px;">
                {{ $fanyi('产品') }}
              </div>
              <div class="goodsTd goodshe goodsNumber" style="margin-left: 150px">
                {{ $fanyi('购买数量') }}
              </div>
              <div class="goodsTd goodshe goodsPrice">
                {{ $fanyi('全部费用') }}
              </div>
            </div>
            <div class="goodsOpt goodsTr" v-for="goodsItem in buyGoodsArr" :key="'good' + goodsItem.id">
              <div class="goodsTd goodsBo goodsName">
                <el-popover placement="right" trigger="hover">
                  <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />

                  <el-image slot="reference" :src="goodsItem.pic">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                    <div slot="placeholder">
                      <i class="el-icon-loading"></i>
                    </div>
                  </el-image>
                </el-popover>
                <el-popover placement="bottom" trigger="hover" width="800">
                  {{ goodsItem.goods_title }}
                  <span slot="reference" class="goodsTitle">
                    {{ goodsItem.goods_title }}
                  </span>
                </el-popover>
              </div>
              <div class="goodsTd goodsBo goodsNumber">
                {{ goodsItem.num }}
              </div>
              <div class="goodsTd goodsBo goodsPrice">
                <p>
                  ${{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(
                        goodsItem.num *
                        goodsItem.price *
                        $parent.datas.exchange_rate
                      )
                    )
                  }}
                </p>
                <p>
                  ({{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(goodsItem.num * goodsItem.price)
                    )
                  }}￥)
                </p>
              </div>
            </div>
          </div>
          <div class="showAllRow">
            <img class="jiahao" src="../../../../../../assets/pulldown_1.svg" alt=""
              @click="showGoodsArrAll = !showGoodsArrAll" v-if="!showGoodsArrAll" />
            <img class="jiahao jian" src="../../../../../../assets/pulldown_0.svg" alt=""
              @click="showGoodsArrAll = !showGoodsArrAll" v-else />
          </div>
        </div>
      </div>
      <div class="ortherBox">
        <div class="operatingPrinciple">
          <h1 class="operatingPrincipletishi">
            {{ $fanyi('后续怎么操作') }}
            <div class="whyIsYuGu" @click="openWhyDia">
              <img src="../../../../../../assets/icon/info_fill.svg" alt="" />
              <span>{{ $fanyi('为什么现在估计装运费用?') }}</span>
            </div>
          </h1>
          <div>
            <div class="tishiOpt">
              <div class="tihsiNum">1</div>
              <div class="tishiCon">
                <h1>{{ $fanyi('我们联系制造商') }}</h1>
                <div>
                  {{
                    $fanyi(
                      '我们联系制造商确认产品购买费用，箱规尺寸等必要信息，从而预估产品的运输成本。'
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="tishiOpt">
              <div class="tihsiNum">2</div>
              <div class="tishiCon">
                <h1>{{ $fanyi('您会在24小时内收到报价') }}</h1>
                <div>
                  {{
                    $fanyi(
                      '这份报价中包括所有类型的预估国际运费（空运、海运和陆运）。'
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="tishiOpt">
              <div class="tihsiNum">3</div>
              <div class="tishiCon">
                <h1>{{ $fanyi('产品的付款') }}</h1>
                <div>
                  {{
                    $fanyi(
                      '我们将预估国际费用发送给您后，您就可以正式购买您的订单的产品了。'
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
          <whyDiaVue ref="whyDia"></whyDiaVue>
        </div>
        <div class="contactUs">
          <h1>{{ $fanyi('你有什么问题吗?') }}</h1>
          <a href="mailto:service@rakumart.us">
            <span><img src="../../../../../../assets/icon/CORREO.svg" alt="" />
              <span>{{ $fanyi('写邮件与我们沟通') }} </span></span>
            <span class="el-icon-arrow-right"></span>
          </a>
          <p @click="chat">
            <span>
              <img src="../../../../../../assets/icon/chatyuan.svg" alt="" />
              <span>{{ $fanyi('使用RAKU-chat与我们沟通') }}</span>
            </span>
            <span class="el-icon-arrow-right"></span>
          </p>
          <p @click="lianxi">
            <span>
              <img src="../../../../../../assets/icon/WHATSAPP.svg" alt="" />
              <span>{{ $fanyi('使用WhatsApp与我们沟通') }}</span>
            </span>
            <span class="el-icon-arrow-right"></span>
          </p>
        </div>
      </div>
    </div>
    <Dialog ref="dialog" :config="config" />
  </div>
</template>
<script>
import whyDiaVue from './whyDia.vue'
import HomePageTop from '../../../../../../components/head/HomePageTop.vue'
import Dialog from '../../../../../../components/public/Dialog.vue'
export default {
  data() {
    return {
      buyGoodsArr: [],
      showGoodsArrAll: false,
      goodsList: [],
      autoplay: true,
      translation_name: '',
      chinese_name: '',
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('是否登录'),
        btnTxt: [this.$fanyi('前往登录'), this.$fanyi('关闭')],
      },
    }
  },
  props: {
    order_sn: {
      type: String,
      default: '',
    },
  },
  components: { HomePageTop, whyDiaVue, Dialog },
  computed: {},
  created() {
    this.getBuyGoodsArr()
    this.getPersonalizedGoods()
  },
  methods: {
    //打开为什么装运是估计的弹窗
    openWhyDia() {
      this.$refs.whyDia.open()
    },
    // 获取推荐商品数据
    getPersonalizedGoods() {
      this.$api.getPersonalizedGoods().then((res) => {
        this.goodsList = res.data.result
        this.chinese_name = res.data.chinese_name
        this.translation_name = res.data.translation_name
        this.ProcessingShopData()
      })
    },
    // 数据处理
    ProcessingShopData() {

      let lack = 6 - (this.goodsList.length % 6) //取得店铺商品数离6个的倍数差多少个商品的数量

      //差多少个就从最前面取多少个填充到最后
      for (let i = 0; i < lack; i++) {
        this.goodsList.push(this.goodsList[i])
      }
      //  分成六个一组
      this.goodsList = this.$fun.changeArr(this.goodsList, 6)

      this.$forceUpdate()
    },
    // 滑动店铺商品列表
    shopCarouselChange(type) {
      if (type == 'left') {
        this.$refs.shopCarousel.prev()
      } else if (type == 'right') {
        this.$refs.shopCarousel.next()
      }
    },
    // 调取聊天窗口
    chat() {
      let element = document.getElementById("zsiq_float");
      // 创建事件
      var event = document.createEvent("MouseEvents");
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent("click", true, true);
      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event);

    },
    // 通过whasttapp
    lianxi() {
      if (this.$store.state.userInfo) {
        let iTop = (window.screen.availHeight - 30 - 700) / 2
        //获得窗口的水平位置
        let iLeft = (window.screen.availWidth - 10 - 1260) / 2
        window.open(
          'https://wa.me/+8615957125759?id=' +
          this.$store.state.userInfo.user_id,
          this.$store.state.userInfo,
          'height=700, width=1260, left=' +
          iLeft +
          ', top=' +
          iTop +
          ', toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no'
        )
      } else {
        this.$refs.dialog.open(
          this.$fanyi('是否前往登录?'),
          () => {
            this.$store.commit('getactivePage', this.$route.fullPath)
            this.$fun.toPage('/login')

          },
          () => { }
        )
      }
    },
    getBuyGoodsArr() {
      let buydata = []
      this.$parent.newList.forEach((shopsItem) => {
        shopsItem.list.forEach((goodsItem) => {
          buydata.push(goodsItem)
        })
      })
      this.buyGoodsArr = buydata

    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../css/mixin';

.placingSuccessfullyPage {
  display: flex;
  align-items: flex-start;
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 40px;

  .orderDetailBox {
    .confirmation {
      width: 800px;
      width: 800px;
      padding: 0 80px;
      height: 426px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      margin-bottom: 20px;

      .confirmationH1 {
        margin: auto;
        height: 155px;

        padding-top: 80px;
        margin-bottom: 120px;

        img {
          margin: auto;
          width: 80px;
          height: 80px;
          margin-bottom: 35px;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #2f2f2f;
        line-height: 26px;
        margin-bottom: 15px;
        text-align: center;
      }

      div {
        font-size: 24px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #222222;
        text-align: center;
      }

      .needToProvide {
        width: 620px;
        height: 127px;
        border-radius: 0px;
        margin: 0 auto;
        padding: 20px 38px;
        font-size: 13px;
        font-weight: 400;
        color: #2f2f2f;
        line-height: 26px;
        word-break: break-all;
        text-align: center;
      }
    }

    // 提示
    .prompt {
      width: 800px;
      height: 302px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      margin-bottom: 20px;
      margin: auto;

      .pic {
        width: 80px;
        height: 80px;
        margin: auto;
        margin-top: 60px;
        margin-bottom: 30px;

        img {
          margin-left: 350px;

          margin: auto;
        }
      }

      .title {
        padding: 0 120px;
        width: 100%;
        height: 72px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #222222;
        text-align: center;
        line-height: 24px;
      }
    }

    .goodsBox {
      width: 800px;
      max-height: 300px;
      background: #ffffff;
      border: 1px solid #e3e3e3;

      border-radius: 0px;
      margin-top: 20px;
      border-radius: 6px;
      padding: 30px 40px;
      position: relative;
      transition: all 0.5s;
      overflow: hidden;

      h1 {
        // margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        line-height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .goodsBoxtishi {
        display: block;
        font-size: 16px;
        height: 17px;
        font-weight: 500;
        color: #2f2f2f;
        line-height: 17px;
        margin-bottom: 10px;
      }

      .goodsArr {
        .goodsHead {
          font-weight: 500 !important;
        }

        .goodsTr {
          display: flex;
          align-items: center;

          &.goodsOpt {
            padding-bottom: 40px;
          }

          .goodsTd {
            font-size: 13px;
            font-weight: 400;
            color: #2f2f2f;
            line-height: 29px;
            flex: 1;

            &.goodshe {
              height: 53px;
              background-color: white;
              display: flex;
              align-items: center;
            }

            &.goodsBo {
              height: 80px;
            }

            &.goodsPrice {
              flex: 0 0 164px;
              display: flex;
              justify-content: center;
              align-items: center;

              // background-color: pink;
              &.goodsBo {
                background: #fff5ee;

                // padding-top: 15px;
                p {
                  font-size: 16px;
                  font-weight: 600;
                  color: #2f2f2f;
                  text-align: center;
                  line-height: 21px;
                }
              }
            }

            &.goodsNumber {
              justify-content: center;
              align-items: center;

              // padding-left: 200px;
              &.goodsBo {
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }

            &.goodsName {
              display: flex;

              /deep/.el-image {
                $imgSize: 80px;
                width: $imgSize;
                height: $imgSize;
                transition: 0.3s;
                text-align: center;
                margin-right: 50px;

                i {
                  font-size: $imgSize/4;
                  line-height: $imgSize;
                }
              }

              .goodsTitle {
                width: 225px;
                height: 82px;
                font-size: 12px;
                font-weight: 400;
                position: relative;
                top: -5px;
                color: #2f2f2f;
                line-height: 21px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }

      .showAllRow {
        height: 48px;
        background: #ffffff;
        border-top: 1px solid #e3e3e3;

        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 4px 4px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .jiahao {
          width: 16px;
          height: 16px;
          margin-right: 32px;
          cursor: pointer;
        }
      }

      &.GoodsArrAll {
        max-height: 5000px !important;
        height: auto;
        transition: all 0.5s;
      }
    }
  }

  .ortherBox {
    margin-left: 40px;

    .operatingPrinciple {
      padding: 40px 30px;
      margin-bottom: 20px;
      width: 560px;
      height: 439px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 6px;

      .operatingPrincipletishi {
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        line-height: 18px;
        font-family: 'Roboto-Regular, Roboto';
        display: flex;
        justify-content: space-between;
        align-items: center;

        .whyIsYuGu {
          display: flex;
          height: 18px;
          align-items: center;
          cursor: pointer;

          span {
            font-size: 13px;
            font-weight: 400;
            text-decoration: underline;
            color: #ff730b;
            line-height: 20px;
          }

          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }
      }

      .tishiOpt {
        min-height: 119px;
        border-bottom: solid 1px #e7e7e7;
        display: flex;
        align-items: flex-start;
        padding: 30px 0;

        .tihsiNum {
          flex: 0 0 41px;
          height: 41px;
          border: 1px solid #e7e7e7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }

        .tishiCon {
          h1 {
            font-size: 16px;
            font-family: 'Roboto-Regular, Roboto';
            font-weight: 400;
            color: #21213b;
            line-height: 14px;
            margin-bottom: 10px;
          }

          >div {

            font-size: 14px;
            font-family: 'Roboto-Regular, Roboto';
            font-weight: 400;
            line-height: 21px;

            color: #66667e;
          }
        }
      }
    }

    .contactUs {
      width: 560px;
      min-height: 216px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 6px;
      padding: 25px;

      h1 {
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        line-height: 18px;
        margin-bottom: 30px;
      }

      p,
      a {
        width: 500px;
        height: 60px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e2e2e2;
        margin-bottom: 10px;
        padding: 18px 20px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #222222;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 20px;
          height: 20px;
          margin-right: 9px;
        }

        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          display: flex;

          .blueFont {
            color: #ff730b;
            align-items: center;
          }
        }

        /deep/ .el-icon-arrow-right {
          text-align: right;
        }
      }
    }
  }
}

/deep/.el-carousel__arrow--right,
/deep/.el-carousel__arrow--left {
  display: none;
  width: 100px;
  height: 100px;
  background: #ffffff;
  opacity: 0.6;
  border-radius: 50%;
}

/deep/ .el-carousel__arrow {
  background: rgba(255, 255, 255, 0.5);

  i {
    color: $homePageSubjectColor;
    font-size: 60px;
    font-weight: 700;
  }
}

/deep/.el-carousel__button {
  display: none;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
}

/deep/.el-carousel__indicator.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  outline: 3px solid $homePageSubjectColor;
  background-color: transparent;
}
</style>
