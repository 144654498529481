<template>
  <div class="modeOofTransportation">
    <whyDiaVue ref="whyDia"></whyDiaVue>
    <!-- 标题，运输选择 -->
    <div class="Transportationchoice">
      <span>{{ $fanyi('选择运输类型以获得最终的运输报价') }}</span>
      <div class="troubleShooting" @click="opentroubleShootingDia">
        <img src="../../../../../../assets/icon/why.svg" alt="" />
        <span>{{ $fanyi('为什么现在估计装运?') }}</span>
      </div>
    </div>

    <!-- 运输物流列表 -->
    <div class="optBox">
      <div v-for="(fangshiItem, fangShiIndex) in orderOfferLogistics" :key="fangShiIndex"
        class="modeOofTransportationOpt" :class="{
          userActOpt: fangShiIndex == userActOpt,
          contraction: fangshiItem.flag == true,
        }">
        <div class="modeOofTransportationOptCon">
          <div class="title">
            <h1 @click.stop="chioseOpt(fangShiIndex, fangshiItem)">
              <div class="activeIcon" :class="{ active: fangShiIndex == userActOpt }">
                <div></div>
              </div>
              <span>{{ $fanyi(fangshiItem.logistics.name) }}</span>

              <div class="tujian" v-if="fangshiItem.is_recommend == 1">
                {{ $fanyi('推荐') }}
              </div>
            </h1>
            <i class="el-icon-arrow-down icon" @click="contraction(fangshiItem.flag, fangShiIndex)"></i>
          </div>
          <div class="importPrice">
            <p>
              <span>{{ $fanyi('预估国际运费') }}:</span>
              <font class="priceNum">$ {{
                $fun.EURNumSegmentation(fangshiItem.international_freight)
              }}</font>
            </p>
            <p>
              <span>{{ $fanyi('预计交货时间') }}:</span>
              <font class="priceNum">{{ fangshiItem.logistics.useful_rule ?
                fangshiItem.logistics.useful_rule.predict_duration : '' }}</font>
            </p>
          </div>
          <div class="transportationDetail">
            <h2>Shipping information:</h2>
            <p>
              {{
                fangshiItem.logistics.useful_rule !== null
                  ? fangshiItem.logistics.useful_rule.description
                  : ''
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import whyDiaVue from './whyDia.vue'
export default {
  data() {
    return {
      userActOpt: 0,
      config: {
        top: '20vh',
        width: '822px',
        title: this.$fanyi('为什么现在估计装运?'),
        btnTxt: [this.$fanyi('已了解')],
      },
    }
  },
  components: { whyDiaVue },
  // 从父级取循环的物流列表
  computed: {
    orderOfferLogistics() {
      if (this.$parent.datas.orderOfferLogistics) {
        this.$parent.datas.orderOfferLogistics.forEach((item) => {
          item.flag = false
        })
      }
      return this.$parent.datas.orderOfferLogistics
    },
  },
  created() {



  },
  methods: {
    // 选择物流选项
    chioseOpt(i, e) {
      if (this.userActOpt == i) {
        this.userActOpt = -1
      } else {
        this.userActOpt = i
        this.$emit('Selectivelogistics', e.id, i)
      }
    },
    // 打开常见问题弹窗
    opentroubleShootingDia() {
      this.$refs.whyDia.open()
    },
    contraction(flag, contraction) {
      if (flag == true) {
        this.$parent.datas.orderOfferLogistics[contraction].flag = false
      }
      if (flag == false) {
        this.$parent.datas.orderOfferLogistics[contraction].flag = true
      }
      this.$forceUpdate()
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../../../../../css/mixin';

.modeOofTransportation {
  width: $pageWidth;
  margin: 40px auto 0;
  margin-bottom: 30px;

  .Transportationchoice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    >span {
      font-size: 18px;
      font-weight: 600;
      color: #2f2f2f;
      line-height: 18px;
    }

    .troubleShooting {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        margin-right: 5px;
      }

      span {
        font-size: 13px;
        font-weight: 400;
        text-decoration: underline;
        color: #ff730b;
        line-height: 18px;
      }
    }
  }

  .optBox {
    display: flex;
    flex-wrap: wrap;

    .modeOofTransportationOpt {
      width: 440px;
      // min-height: 258px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      margin-bottom: 30px;
      margin-right: 38px;
      padding-bottom: 20px;
      transition: all 0.5s;

      overflow: hidden;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .modeOofTransportationOptCon {
        width: 440px;

        height: 100%;
        transition: 0.3s;
        border-radius: 6px;
        background: #ffffff;
        // border: 2px solid #e2e2e2;
        // overflow: hidden;
      }

      .title {
        display: flex;
        align-items: center;

        h1 {
          height: 84px;
          width: 450px;
          display: flex;
          align-items: center;
          padding: 0 38px 0 34px;
          cursor: pointer;
          position: relative;

          .tujian {
            position: absolute;
            left: 0;
            top: 0;
            width: 80px;
            height: 20px;
            background: #608bff;
            border-radius: 0px 0px 6px 0px;
            border: 1px solid #608bff;
            font-size: 12px;
            font-family: Roboto-Regular, Roboto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 20px;
            font-weight: 400;
            color: #ffffff;
          }

          .activeIcon {
            width: 16px;
            height: 16px;
            background: #ffffff;
            border: 1px solid #e8e8e8;
            border-radius: 50%;
            position: relative;
            margin-right: 10px;

            div {
              display: none;
            }
          }

          >span {
            font-size: 18px;
            font-weight: 600;
            color: #2f2f2f;
            line-height: 23px;
          }
        }

        .el-icon-arrow-down {
          margin-left: 50px;
          font-weight: bold;
          transition: 0.3s;
          margin-right: 30px;
        }
      }

      .importPrice {
        padding: 0 20px 0px 30px;

        p {
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 23px;
          height: 20px;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 15px;

          span {
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #222222;
          }

          .priceNum {
            font-size: 16px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 600;
            color: #222222;
          }
        }
      }

      .transportationDetail {
        margin-top: 30px;
        padding: 0 20px 0px 30px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #999999;
        line-height: 20px;

        h2 {
          font-size: 14px;
          font-weight: 500;
        }

        p {
          font-size: 14px;
          margin-top: 5px;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: solid 1px #e8e8e8;

          &:last-child {
            border-bottom: none;
          }

          color: #868686;
          display: flex;
          align-items: center;

          font {
            margin-left: auto;
          }
        }
      }
    }

    .contraction {
      width: 440px;
      height: 80px !important;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      margin-bottom: 30px;
      margin-right: 29px;
      overflow: hidden;
      transition: all 0.5s;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .el-icon-arrow-down {
        margin-left: auto;
        font-weight: bold;
        transform: rotate(180deg);
        transition: all 0.5s;
      }
    }

    // 点击后得样式
    .userActOpt {
      border: 2px solid #608bff !important;

      .activeIcon {
        width: 16px;
        height: 16px;
        background: #ffffff;
        border: 1px solid #1c2899;
        border-radius: 50%;
        position: relative;
        margin-right: 10px;

        div {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          background: #1c2899;
          border: 1px solid #1c2899;
          border-radius: 50%;
        }
      }
    }

    .active {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 1px solid #1c2899 !important;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;

      div {
        display: block !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;

        background: #1c2899 !important;
        border: 1px solid #1c2899 !important;
        border-radius: 50%;
      }
    }


  }
}
</style>
